<template>
  <div style="padding: 0px;">
    <van-nav-bar
        :title="(click_item.fund_name === undefined ? '详情' : click_item.fund_name)"
        left-arrow
        :border="false"
        :fixed="true"
        @click-left="onClickLeft"
    >
<!--      <van-icon slot="left" color="#000" name="arrow-left" size=".46rem" ></van-icon>-->
    </van-nav-bar>
    <div style="height: 100%; width: 100%; padding: 0 0;background-color: #F7F8FA;box-sizing:border-box;">
      <div class="top_text">
        <p>份额净值：{{ click_item.net_value || ''  }}</p>
<!--        <span>{{click_item.fund_name === undefined ? '详情' : click_item.fund_name)}}</span>-->
      </div>
      <van-row gutter="15" style="padding-left: 10px;padding-right: 10px;">
        <van-col span="24">
          <van-row  gutter="15" style="margin-top: 10px;margin-bottom: 10px;font-size: 14px; color: #aaaaaa">
            <van-col span="8" v-if="click_item.fund_code">
              <span>{{ (click_item.fund_code === undefined ? '' : click_item.fund_code) }}</span>
            </van-col>
            <van-col span="8" v-if="click_item.fund_account">
              <span>{{ (click_item.fund_account === undefined ? '' : click_item.fund_account) }}</span>
            </van-col>
            <van-col span="24" v-if="click_item.fund_code">
              <van-cell-group inset title="基金信息">
              <van-cell title="名称" is-link @click="toProduct"  :value="(click_item.fund_name === undefined ? '' : click_item.fund_name)" />
              </van-cell-group>
            </van-col>
          </van-row>
        </van-col>
            <van-col span="24">
              <van-cell-group inset title="份额">
                <van-cell title="确认份额" :value="(click_item.confirmation_share === undefined ? '' : click_item.confirmation_share)" />
                <van-cell title="确认金额" :value="(click_item.confirmation_amount === undefined ? '' : click_item.confirmation_amount + '万')" />
<!--                <van-cell title="份额净值" :value="(click_item.net_value === undefined ? '' : click_item.net_value)" />-->
              </van-cell-group>
            </van-col>

        <van-col span="24">
<!--          <van-divider content-position="left">账户：({{ click_item.customer_name }}C{{ click_item.risk_level }})</van-divider>-->
          <van-cell-group inset title="账户信息">
            <van-cell title="账户" :value="(click_item.customer_name === undefined ? '' : click_item.customer_name)" />
            <van-cell title="风险级别" :value="(click_item.risk_level === undefined ? '' : 'C'+click_item.risk_level)" />
            <van-cell title="认购账户" :value="(click_item.subscribe_account === undefined ? '' : click_item.subscribe_account)" />
            <van-cell title="开户行" :value="(click_item.subscribe_bank === undefined ? '' : click_item.subscribe_bank)" />
            <van-cell title="交易账号" :value="(click_item.transaction_account === undefined ? '' : click_item.transaction_account)" />
            <van-cell title="确认日期" :value="(click_item.confirmation_date === undefined ? '' : click_item.confirmation_date)" />
            <van-cell title="确认标志" :value="(click_item.confirmation_sign === undefined ? '' : click_item.confirmation_sign)" />
            <van-cell title="委托方式" :value="(click_item.entrust_method === undefined ? '' : click_item.entrust_method)" />
          </van-cell-group>
        </van-col>
        <!--      分界线 1-->
<!--        <van-col span="8">-->
<!--          <p>认购账户</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.subscribe_account === undefined ? '' : click_item.subscribe_account) }}</p>-->
<!--        </van-col>-->
<!--        <van-col span="8">-->
<!--          <p>开户行</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.subscribe_bank === undefined ? '' : click_item.subscribe_bank) }}</p>-->
<!--        </van-col>-->
<!--        <van-col span="8">-->
<!--          <p>交易账号</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.transaction_account === undefined ? '' : click_item.transaction_account) }}</p>-->
<!--        </van-col>-->
<!--        <van-col span="24" style="margin-top: 20px;">-->
<!--          <van-row gutter="15">-->
<!--            <van-col span="8">-->
<!--              <p>确认日期</p>-->
<!--              <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.confirmation_date === undefined ? '' : click_item.confirmation_date) }}</p>-->
<!--            </van-col>-->
<!--            <van-col span="8">-->
<!--              <p>确认标志</p>-->
<!--              <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.confirmation_sign === undefined ? '' : click_item.confirmation_sign) }}</p>-->
<!--            </van-col>-->
<!--            <van-col span="8">-->
<!--              <p>委托方式</p>-->
<!--              <p style="margin-top: 10px;color: cornflowerblue">{{ click_item.entrust_method === undefined ? '' : click_item.entrust_method }}</p>-->
<!--            </van-col>-->
<!--          </van-row>-->
<!--        </van-col>-->

        <van-col span="24">
          <van-cell-group inset title="基金管理">
            <van-cell title="销售商" :value="(click_item.seller === undefined ? '' : click_item.seller)" />
            <van-cell title="份额类别" :value="(click_item.share_classes === undefined ? '' : click_item.share_classes)" />
            <van-cell title="锁定到日期" :value="(click_item.lock_date === undefined ? '' : click_item.lock_date)" />
          </van-cell-group>
        </van-col>
<!--        <van-col span="8">-->
<!--          <p>销售商</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.seller === undefined ? '' : click_item.seller) }}</p>-->
<!--        </van-col>-->
<!--        <van-col span="8">-->
<!--          <p>份额类别</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.share_classes === undefined ? '' : click_item.share_classes) }}</p>-->
<!--        </van-col>-->
<!--        <van-col span="8">-->
<!--          <p>锁定到日期</p>-->
<!--          <p style="margin-top: 10px;color: cornflowerblue">{{ (click_item.lock_date === undefined ? '' : click_item.lock_date) }}</p>-->
<!--        </van-col>-->
      </van-row>
    </div>
  </div>
</template>

<script>
import LineChart from "./LineChart";
import qs from "qs";
import {Base64} from "js-base64";

export default {
  name: "ProductDetail",
  components: {
    LineChart
  },
  data() {
    return {
      click_item: undefined,
      chartData: {
        expectedData:[],
        actualData:[],
        time:[],
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    toProduct () {
      this.$http.get('/product/customer_product/',{ id: this.click_item.product_id })
          .then(res => {
            if (res && res.code === 1000) {
             const data = res.data.entries
              if (data && data.length>0) {
                const record = data[0]
                this.$router.push({ path: '/product/'+ record.id, query: { data: Base64.encode(qs.stringify(record))} })
              }
            }
          })
    },
    netWorthData () {
      // this.$http.get('/product/net_worth_update/' + this.click_item.product_id + '/')
      //     .then(res => {
      //       if (res && res.code === 1000) {
      //        const data = res.data
      //         if (data && data.length>0) {
      //           this.chartData.actualData = data.map(item => {
      //             return item.net_worth;
      //           });
      //
      //           this.chartData.time = data.map(item => {
      //             return item.create_time.replaceAll('T', '');
      //           });
      //         }
      //       }
      //     })
    }
  },
  created() {
    // console.log(this.$route.query.data);
    this.click_item = qs.parse(Base64.decode(this.$route.query.data))
    console.log('我的线切割',this.click_item);
    this.netWorthData();
  }
}
</script>

<style scoped>
.top_text {
  font-size: 34px;
  color: white;
  padding: 30px 0px;
  height: 130px;
  line-height: 130px;
  background-color: #BB0A24;
  width: 100%;
  font-weight: bolder;
  text-align: center;
}
</style>
